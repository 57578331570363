<template>
    <Transition>
        <div class="text-center mt-10">
            <img src="/img/doro-logo-img-transparent.png" height="100" width="100" class="inline-block" alt="doro logo">
            <div v-if="$route.params.type === 'newPatient'" class="formkit-step-inner">
                <h2 class="text-2xl doroHeadline">Sie waren noch nie als Patient:in in unserer Praxis?</h2>
                <div class="text-md">Bitte kontaktieren Sie uns für einen persönlichen Termin.</div>
                <div v-if="userid==='pH7bebe5ugd76giweth'" class="font-sans mb-5 mt-5">
                    <div class="mb-5 pt-0 mx-auto text-center">
                        <a class="doro_button mb-8 block" href="https://praxis-fuer-familiengesundheit.de/kontakt/" target="_parent">Telefonischer Kontakt</a>
                    </div>
                </div>

            </div>
            <div v-else-if="$route.params.type === 'fehler'">
                <h2 class="text-2xl doroHeadline">Technischer Fehler.</h2>
                <div class="text-md">Es ist ein Fehler vorgefallen, bitte noch einmal versuchen.</div>
            </div>
            <div  v-else-if="$route.params.type === 'gesendet'">
                <h2 class="text-2xl doroHeadline">Vielen Dank!</h2>
                <h3 class="text-xl mb-5">Ihre Anfrage wurde übermittelt.</h3>
                <div class="text-md mb-5 px-5">Innerhalb unserer Öffnungszeiten melden wir uns schnellstmöglich zurück.</div>
                <div class="text-md mb-5 px-5"> Bestellte Rezepte/Überweisungen können am Folgetag in unserer Praxis abgeholt werden.</div>
            </div>
            <div v-else>
                <h2 class="text-2xl doroHeadline">Not Found</h2>
                <div class="text-md mb-2 doroSubline">Diese Resource wurde nicht gefunden.</div>
            </div>
            <buttonFormReset />
        </div>
    </Transition>
</template>

<script>
import * as textstr from "@/assets/texts.json"
import buttonFormReset from "@/components/ButtonFormReset.vue";
export default {
    name: "messagesView",
    components: { buttonFormReset },
    data () {
        //useFormDataStore()
        return {
            texts : textstr
        }
    },
    setup () {
        const userid = localStorage.getItem('userid')
        return {
            userid
        }
    }
}
</script>