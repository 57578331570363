<template>
    <div class="generalRequestRoot">
      <FormKit type="form" id="generalRequestWrapper" :actions="false" @submit="submitForm">
        <FormKit type="multi-step"
            name="generalRequest"
            tab-style="progress" :hide-progress-labels="true"
            class="grid grid-cols-1 place-items-stretch"
            :allow-incomplete="false"
        >
            <FormKit type="step" name="isPatient"
                     next-label="Weiter"
                     prev-label="Zurück"
                     :beforeStepChange="checkAllowedPatients"
            >
                <h2 class="text-2xl doroHeadline">{{texts.isPatientHeadline}}</h2>
                <div class="text-md mb-2 doroSubLine"></div>
                <FormKit
                        v-model="formData.patientType"
                        type="radio"
                        name="Patientenart"
                        preserve="true"
                        validation="required"
                        :options="[
                            { label: texts.isPatientNew, value: 'newPatient' },
                            { label: texts.isPatientExisting, value: 'existingPatient'}
                          ]"
                />
                <buttonFormReset @clickFormReset="clickFormReset"/>
            </FormKit>
          <FormKit type="step" name="generalRequest01"
                   next-label="Weiter"
                   prev-label="Zurück"
          >
            <h2 class="text-2xl doroHeadline">{{texts.GR_Headline}}</h2>
            <div class="text-md mb-3 pt-0 text-left">{{texts.GR_SubLine}}</div>
            <FormKit
                v-model="formData.data.generalrequest"
                type="textarea"
                label="Anfrage"
                name="generalrequest"
                validation="required"
                placeholder="Ihre Anfrage"
            />
          </FormKit>
          <FormKit type="step" name="patientData"
                   next-label="Weiter"
                   prev-label="Zurück">
            <h2 class="text-2xl doroHeadline">{{texts.patientDataHeadline}}</h2>
            <div class="text-md mb-3 pt-0">{{texts.patientDataSubLine}}</div>
            <FormKit
                v-model="formData.firstname"
                type="text"
                label="Vorname"
                name="name"
                validation="required"
                placeholder="Ihr Vorname"
            />
            <FormKit
                v-model="formData.name"
                type="text"
                label="Name"
                name="name"
                validation="required"
                placeholder="Ihr Nachname"
            />
            <FormKit
                type="date"
                label="Geburtstag (optional)"
                name="birthday"
                v-model="formData.birthday"
            />
            </FormKit>
            <FormKit type="step" name="patientContact"
                     next-label="Weiter"
                     prev-label="Zurück">
              <h2 class="text-2xl doroHeadline">{{texts.patientContactHeadline}}</h2>
              <div class="text-md mb-3 pt-0">{{texts.patientContactSubLine}}</div>
            <FormKit
                v-model="formData.email"
                type="email"
                label="Email"
                name="email"
                validation="required|email"
                placeholder="Ihre Emailadresse"
            />
            <FormKit
                v-model="formData.phone"
                type="text"
                label="Telefonnummer (optional)"
                name="phone"
                placeholder="Ihre Telefonnummer"
            />
          </FormKit>
          <FormKit type="step" name="insuranceCard"
                 next-label="Weiter"
                 prev-label="Zurück"
          >
          <h2 class="text-2xl doroHeadline">{{texts.insuranceCardHeadline}}</h2>
          <div class="text-md mb-2 doroSubLine">{{texts.insuranceCardSubLine}}</div>
          <FormKit
              v-model="formData.data.insuranceCard"
              beforeStepChange="checkAllowedPatients"
              type="radio"
              name="Chipkarte"
              preserve="true"
              validation="required"
              :options="[   { label: 'Ja', value: 'Ja' },
                            { label: 'Nein', value: 'Nein'}
              ]"
          />
        </FormKit>
          <FormKit type="step" name="summary" prev-label="Zurück">
            <h2 class="text-2xl doroHeadline">{{texts.summaryHeadline}}</h2>
            <div class="text-md mb-4 pt-0">{{texts.summarySubLine}}</div>
            <div class="text-left grid grid-cols-2 gap-2 mb-8">
              <div>Anfrage</div>
              <div>{{formData.data.generalrequest}}</div>
              <div>Chipkarte eingelesen:</div>
              <div>{{formData.data.insuranceCard}}</div>
              <div class="col-span-2 mt-8">Ihre Daten:</div>
              <div>Vorname:</div>
              <div>{{formData.firstname}}</div>
              <div>Name:</div>
              <div>{{formData.name}}</div>
              <div v-if="formData.birthday">Geburtstag:</div>
              <div v-if="formData.birthday">{{format_date(formData.birthday)}}</div>
              <div>Email:</div>
              <div>{{formData.email}}</div>
              <div v-if="formData.phone">Telefonnummer:</div>
              <div v-if="formData.phone">{{formData.phone}}</div>
            </div>
            <FormKit
                :disabled="isLoading"
                type="submit"
                name="Senden"
                :config="{classes:{
                  outer:'formSendOuter formkit-step-next pr-5 text-right',
                  input:'doro_button'
                }}"
            />
          </FormKit>
        </FormKit>
      </FormKit>
    </div>
</template>

<script>
import buttonFormReset from '@/components/ButtonFormReset.vue';
import moment from "moment/moment";
import * as textstr from '@/assets/texts.json'
import router from "@/router"
export default {
  name: 'FormGeneralRequest',
  components: { buttonFormReset },
  methods : {
    format_date(value){
      if (value) {
        return moment(String(value)).format('DD.MM.YYYY')
      }
    },
    submitForm() {
      this.$root.$options.methods.submitForm(this.formData)
    },
    checkAllowedPatients () {
      if(this.formData.patientType === 'newPatient'){
        router.push({ path: `/meldung/${this.formData.patientType}` })
      }
    }
  },
  data () {
    let phone
    return {
      phone,
      texts : textstr
    }
  },
  setup () {
    return {
      emits:['showNoNewPatients','clickFormReset'],
      result : 'showNoNewPatients',
      submitError : '',
      isLoading : false,
      formData: {
        type        : 'generalRequest',
        patientType : '',
        firstname   : '',
        name        : '',
        birthday    : '',
        phone       : '',
        email       : '',
        data    : {
          generalrequest: '',
          insuranceCard:''
        }
      }
    }
  }
}

</script>