<template>
  <div class="formResetButton ">
      <router-link to="/" custom v-slot="{ navigate }">
          <button @click="navigate" id="formResetButton" class="doro_button formkit-input" @keypress.enter="navigate" role="link">{{texts.backButtonText}}</button>
      </router-link>
  </div>
</template>

<script>
import * as textstr from '@/assets/texts.json'
export default {
  name: 'buttonFormReset',
  methods: {
  },
   data(){
     return {
         texts: textstr
     }
   }
}

</script>