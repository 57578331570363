<template>
    <div class="app-advanced">
        <header  transition="expand" class="mx-auto mt-5">
            <img src="/img/doro-logo-img-transparent.png" height="100" width="100" class="inline-block" alt="doro Logo"/>
            <h2 class="text-2xl doroHeadline">{{texts.mainHeadline}}</h2>
            <div class="text-md mb-2 doroSubLine">{{texts.mainSubLine}}</div>
        </header>
        <div :class="wrapper_class">
            <router-link to="/wiederholungsrezept" custom v-slot="{ navigate }">
                <button @click="navigate" class="doro_button doro_button_full" @keypress.enter="navigate" role="link">Wiederholungsrezept</button>
            </router-link>
        </div>
        <div :class="wrapper_class">
            <router-link to="/ueberweisung" custom v-slot="{ navigate }">
                <button @click="navigate" class="doro_button doro_button_full" @keypress.enter="navigate" role="link">Überweisung</button>
            </router-link>
        </div>
        <div :class="wrapper_class">
            <router-link to="/terminanfrage" custom v-slot="{ navigate }">
                <button @click="navigate" class="doro_button doro_button_full" @keypress.enter="navigate" role="link">Terminanfrage</button>
            </router-link>
        </div>
        <div :class="wrapper_class">
            <router-link to="/anfrage" custom v-slot="{ navigate }">
                <button @click="navigate" class="doro_button doro_button_full" @keypress.enter="navigate" role="link">Andere Anfrage</button>
            </router-link>
        </div>
    </div>
</template>

<script>
import * as textstr from "/src/assets/texts.json"
import packageInfo from "../../package.json";
export default {
    name: "HomePage",
    data: () => ({
        packageInfo,
        texts : textstr
    }),
    setup() {
        const wrapper_class = "mb-3 pt-0 mx-auto text-center"
        return {
            wrapper_class
        }
    }
}

</script>