import { createRouter, createWebHistory } from 'vue-router'
import Home from '@/views/Home.vue'
import messages from '@/views/messages.vue'
import appointmentRequest from '@/views/appointmentRequest.vue'
import generalRequest from '@/views/generalRequest.vue'
import repeatPrescription from '@/views/repeatPrescription.vue'
import transferNote from '@/views/transferNote.vue'

const routes = [
    {
        path: '/',
        name: 'Home',
        component: Home
    },
    {
        path: '/wiederholungsrezept',
        name: 'Wiederholungsrezept',
        component: repeatPrescription
    },
    {
        path: '/ueberweisung/',
        name: 'Überweisung',
        component: transferNote
    },
    {
        path: '/terminanfrage/',
        name: 'Terminanfrage',
        component: appointmentRequest
    },
    {
        path: '/anfrage/',
        name: 'Andere Anfrage',
        component: generalRequest
    },
    {
        path: '/meldung/:type',
        name: 'Meldung',
        component: messages
    },
    {
        path: '/*',
        name: '404',
        component: messages
    }
]

const router = createRouter({ history: createWebHistory(), routes })
export default router